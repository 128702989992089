import { doQueryForward, doActionForward } from "@/api/core";

export default {
    name: "contactUs",
    data() {
        return {
            banner: 'http://public.wencaishui.com/images/learning/pc/contact-2.jpeg',
            form: {
                company: undefined,
                name: undefined,
                phone: undefined,
                comments: undefined
            },
            rules: {
                company: [
                    { required: true, message: '请输入公司名', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入您的手机号', trigger: 'blur' },
                    { validator: this.$validateUtils.validateMobilePhone }
                ],
                comments: [
                    { required: true, message: '请输入合作简述', trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        resetContactForm() {
            const that = this
            that.form = {
                company: undefined,
                name: undefined,
                phone: undefined,
                comments: undefined
            }
            that.$refs['contact_form'].resetFields()
        },
        submitContactForm() {
            const that = this

            that.$refs['contact_form'].validate((valid, fields) =>{
                if (valid) {
                    doActionForward({
                        action_code: 'LNF0',
                        param_str2: that.form.company,
                        param_str3: that.form.name,
                        param_str4: that.form.phone,
                        param_str5: that.form.comments
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK ) {
                            if(res.data.errcode >= that.$consts.biz.OK) {
                                that.$message.success('提交成功！请等待我们的回访，谢谢')
                                that.resetContactForm()
                            }else {
                                that.$message.error(res.data.errmsg)
                            }
                        }else {
                            that.$message.error(that.$consts.biz.FAIL_MSG)
                        }
                    })
                }
            })
        }
    }
}
